import React from "react"
import {Sorter} from "lib"
export const columnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Company Code",
    dataIndex: "companies['id']",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["company_code"]}</>
    },
    isSearchable: true
  },
  {
    title: "AWS GL",
    dataIndex: "aws_gl",
    width: "5%",
    editable: false,
    isSearchable: false
  },
  {
    title: "GCL GL",
    dataIndex: "gcp_gl",
    width: "5%",
    editable: false,
    isSearchable: false
  },
  {
    title: "Azure GL",
    dataIndex: "azure_gl",
    width: "5%",
    editable: false,
    isSearchable: false
  },
  {
    title: "Owner",
    dataIndex: "cloud_contact['id']",
    width: "11%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    sorter: Sorter.TEXT,
    width: "6%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Department",
    dataIndex: "departments['id']",
    sorter: Sorter.TEXT,
    width: "13%",
    render: (text, record) => {
      return <>{record["department_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Active on",
    dataIndex: "active_at",
    sorter: Sorter.DATE,
    width: "8%",
    editable: true
  },
  {
    title: "Expired on",
    dataIndex: "expired_at",
    width: "8%",
    sorter: Sorter.DATE,
    editable: true
  },
  {
    title: "Replacement Cost Center",
    dataIndex: "new_cost_center['id']",
    sorter: Sorter.TEXT,
    width: "6.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_cost_center"]}</>
    },
    isSearchable: true
  },
  {
    title: "Backup CC",
    dataIndex: "alt_cost_center['id']",
    sorter: Sorter.TEXT,
    width: "6%",
    editable: true,
    render: (text, record) => {
      return <>{record["alternate_cost_center"]}</>
    },
    isSearchable: true
  },
  {
    title: "Created on",
    editable: true,
    dataIndex: "created_at",
    sorter: Sorter.DATE,
    width: "6%"
  }
];

export const addColumnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
  },
  {
    title: "Company Code",
    dataIndex: "companies['id']",
    width: "7%",
    editable: true,
    render: (text, record) => {
      return <>{record["company_code"]}</>
    }
  },
  {
    title: "Owner",
    dataIndex: "cloud_contact['id']",
    width: "13%",
    editable: true,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    width: "6%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true
  },
  {
    title: "Department",
    dataIndex: "departments['id']",
    width: "13%",
    render: (text, record) => {
      return <>{record["department_name"]}</>
    },
    editable: true
  },


  {
    title: "Active on",
    dataIndex: "active_at",
    width: "8%",
    editable: true
  },
  {
    title: "Expired on",
    dataIndex: "expired_at",
    width: "8%",
    editable: true
  },
  {
    title: "Replacement Cost Center",
    dataIndex: "new_cost_center['id']",
    width: "6.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_cost_center"]}</>
    }
  },
  {
    title: "Backup CC",
    dataIndex: "alt_cost_center['id']",
    width: "7.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["alternate_cost_center"]}</>
    }
  },
  {
    title: "Created on",
    editable: false,
    dataIndex: "created_at",
    width: "6%",
  }
];
